import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { themeStyles, presets } from '../utils/theme'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import TitleBar from '../components/TitleBar'


const styles = {
  container: {
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    [presets.Tablet]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    }
  },
  featuredImage: {
    minWidth: '270px',
    marginLeft: '0px',
    marginBottom: '20px',
    [presets.Tablet]: {
      marginBottom: '0px',
      marginLeft: '20px',
    }
  }
}

export const EmployeePageTemplate = ({
  content,
  contentComponent,
  title,
  name,
  helmet,
  featuredImage,
}) => {
  const PostContent = contentComponent || Content

  return (
    <div>
      <TitleBar
        title={name}
        links={[
          {text: title, link: null},
        ]}
        linkAlign='flex-start'
      />
      <section className="section" css={styles.container}>
        {helmet || ''}

        <PostContent content={content} />
        {featuredImage ? (
          <div css={styles.featuredImage}>
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredImage,
                alt: `featured image thumbnail for post ${title}`,
              }}
            />
          </div>
        ) : null}
      </section>
    </div>
  )
}

EmployeePageTemplate.propTypes = {
  content: PropTypes.object.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  metadataTitle: PropTypes.string,
  metadataDescription: PropTypes.string,
  helmet: PropTypes.object,
  name: PropTypes.string,
  featuredImage: PropTypes.object,
}

const EmployeePage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout metadataDescription={post.frontmatter.metadataDescription} metadataTitle={post.frontmatter.metadataTitle}>
      <EmployeePageTemplate
        content={post.htmlAst}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        featuredImage={post.frontmatter.featuredImage}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        name={post.frontmatter.name}
      />
    </Layout>
  )
}

EmployeePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default EmployeePage

export const pageQuery = graphql`
  query EmployeePageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        metadataTitle
        metadataDescription
        description
        name
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
